import React, { useEffect, useState } from "react"

// Providers
import { useTheme } from "../context/ThemeContext"

// Utilities
import lazyLoad from "../utilities/handleLazyLoad"

// Data
import FULL_APPAREL_DATA from "../../static/data/full-apparel"

// Components
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const Loader = lazyLoad(() => import("../components/Loader/Loader"))
const ModalSizeGuide = lazyLoad(() => import("../components/Modals/ModalSizeGuide"))
const SuggestedProducts = lazyLoad(() => import("../components/SuggestedProducts/SuggestedProducts"))
const ProductInfoApparel = lazyLoad(() => import("../components/ProductInfo/ProductInfoApparel"))

const ApparelTemplate = ({ location, pageContext }) => {
  const { product } = pageContext
  const { pageLoader } = useTheme()

  const shopifyProductId = product.shopifyId
  const currentProduct = FULL_APPAREL_DATA[shopifyProductId]

  /*============================
    CUSTOM GTM EVENT
  =============================*/
  useEffect(() => {
    let items = [
      {
        item_id: product.variants[0].shopifyId,
        item_name: product.title,
        currency: "USD",
        item_brand: "Betty Rocker",
        item_category: currentProduct.type,
        price: +product.variants[0].price,
        quantity: 1,
      },
    ]
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          item_list_name: currentProduct.type,
          items: items,
        },
      })
    }
  }, [])

  const [loader, setLoader] = useState(true)

  /*============================
    PRODUCT DATA
  =============================*/
  const [title, setTitle] = useState("")
  const [selectedColor, setSelectedColor] = useState("")

  useEffect(() => {
    if (!product) return

    // Set country
    if (window.localStorage.getItem("country")) {
      setLoader(false)
    }

    // Set title
    setTitle(product.title)

    // Set color
    setSelectedColor(location.state && location.state.selectedProductColor ? location.state.selectedProductColor : "")
  }, [product, location.state])

  /*============================
    SIZE GUIDE MODAL
  =============================*/
  const [isModalSizeGuideOpen, setIsModalSizeGuideOpen] = useState(false)
  const [sizeGuideProduct, setSizeGuideProduct] = useState(null)

  const handleOpenModalSizeGuide = product => {
    setSizeGuideProduct(product)
    setIsModalSizeGuideOpen(true)
  }

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isModalSizeGuideOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isModalSizeGuideOpen])

  return (
    <>
      {currentProduct ? (
        <>
          {/*page title and og share*/}
          <Seo
            pageTitle={`${title} | The Betty Rocker`}
            title={product.title}
            description="Checkout the current collection of cute and comfortable Betty Rocker apparel!"
            url={`https://store.thebettyrocker.com/products/${product.handle}`}
            imgSrc={`https://store.thebettyrocker.com/images/fb-share/${product.handle}-fb.jpg`}
          />

          <section className="bg--section pt--xxxxs-20 pt--xs-30">
            <div className="container">
              <ProductInfoApparel
                isPopup={false}
                handleOpenQuickBuyModal={() => {}}
                shopifyProduct={product}
                product={currentProduct}
                handleOpenModalSizeGuide={handleOpenModalSizeGuide}
                selectedProductColor={selectedColor}
              />
            </div>
          </section>

          <SuggestedProducts
            currentItemId={shopifyProductId}
            handleOpenModalSizeGuide={handleOpenModalSizeGuide}
            isApparel={true}
            isShort={currentProduct.type === "merchandise"}
            isCart={false}
          />
        </>
      ) : (
        <Loader isLoader={pageLoader || loader} />
      )}

      {isModalSizeGuideOpen && (
        <ModalSizeGuide
          shopifyProduct={sizeGuideProduct}
          handleCloseModalSizeGuide={() => setIsModalSizeGuideOpen(false)}
        />
      )}
    </>
  )
}
export default ApparelTemplate
